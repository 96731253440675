import { Action, createReducer, on } from '@ngrx/store';
import { mapValues } from 'lodash';

import * as fromActions from './actions';
import { initialState, State } from './state';

export const reducer = createReducer(
  initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { scenarios }) => ({
    ...state,
    scenarios,
    isLoading: false,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createSuccess, (state, { scenario }) => ({
    ...state,
    scenarios: mapValues(state.scenarios, (propertyScenarios, propertyId) => {
      if (scenario.property_id.includes(+propertyId)) {
        return [...propertyScenarios, scenario];
      }
    }),
  })),

  on(fromActions.updateSuccess, (state, { scenario }) => ({
    ...state,
    scenarios: mapValues(state.scenarios, (propertyScenarios) => {
      return propertyScenarios.map((propertyScenario) => {
        if (propertyScenario.id === scenario.id) {
          return scenario;
        }

        return propertyScenario;
      });
    }),
  })),

  on(fromActions.deleteRequest, (state) => ({ ...state, isLoading: true })),
  on(fromActions.deleteSuccess, (state, { scenarioId }) => ({
    ...state,
    isLoading: false,
    scenarios: mapValues(state.scenarios, (propertyScenarios) => {
      return propertyScenarios.filter((propertyScenario) => {
        return propertyScenario.id !== scenarioId;
      });
    }),
  })),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),

  on(fromActions.resetState, () => initialState),
);

export function housekeeperScenariosReducer(
  state: State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
