import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { effectHooks } from '../../helpers';
import { HousekeeperScenario } from '../../models';
import { FileSaverService, HousekeeperScenariosService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class HousekeeperScenariosStoreEffects {
  constructor(
    private dataService: HousekeeperScenariosService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private fileSaverService: FileSaverService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties }) =>
        this.dataService.load(properties).pipe(
          map(
            ({
              data: scenarios,
            }: IResponseSuccess<Record<number, HousekeeperScenario[]>>) => {
              return fromActions.loadSuccess({ scenarios });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, onSuccess, onFailure }) =>
        this.dataService.create(request).pipe(
          effectHooks({ onSuccess, onFailure }),
          map(
            ({ data: [scenario] }: IResponseSuccess<HousekeeperScenario[]>) => {
              this.notifications.success('notifications.generic_success');
              return fromActions.createSuccess({ scenario });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, onSuccess, onFailure }) =>
        this.dataService.update(request).pipe(
          effectHooks({ onSuccess, onFailure }),
          map(
            ({ data: [scenario] }: IResponseSuccess<HousekeeperScenario[]>) => {
              this.notifications.success('notifications.generic_success');
              return fromActions.updateSuccess({ scenario });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ scenarioId }) =>
        this.dataService.delete(scenarioId).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.deleteSuccess({ scenarioId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportLogsRequest),
      switchMap(({ request }) =>
        this.dataService.export(request).pipe(
          map((file: File) => {
            this.notifications.success('notifications.generic_success');
            this.fileSaverService.saveAs(file, file.name);
            return fromActions.exportLogsSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.exportLogsFailure(error));
          }),
        ),
      ),
    ),
  );
}
