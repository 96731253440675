import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>('housekeeperScenarios');

export const selectScenarios = createSelector(
  selectState,
  (state) => state.scenarios,
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectError = createSelector(selectState, (state) => state.error);
