import { HousekeeperScenario } from '../../models';

export interface State {
  scenarios: Record<number, HousekeeperScenario[]>;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  scenarios: null,
  isLoading: false,
  error: null,
};
