import { createAction, props } from '@ngrx/store';

import { ExportScenariosLogsRequest, HousekeeperScenario } from '../../models';

interface UpsertRequest {
  request: HousekeeperScenario;
  onSuccess?: () => void;
  onFailure?: () => void;
}

export const loadRequest = createAction(
  '[Housekeeper Scenarios] Load Request',
  props<{ properties: number[] }>(),
);
export const loadSuccess = createAction(
  '[Housekeeper Scenarios] Load Success',
  props<{ scenarios: Record<number, HousekeeperScenario[]> }>(),
);
export const loadFailure = createAction(
  '[Housekeeper Scenarios] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Housekeeper Scenarios] Create Request',
  props<UpsertRequest>(),
);
export const createSuccess = createAction(
  '[Housekeeper Scenarios] Create Success',
  props<{
    scenario: HousekeeperScenario;
  }>(),
);
export const createFailure = createAction(
  '[Housekeeper Scenarios] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Housekeeper Scenarios] Update Request',
  props<UpsertRequest>(),
);
export const updateSuccess = createAction(
  '[Housekeeper Scenarios] Update Success',
  props<{
    scenario: HousekeeperScenario;
  }>(),
);
export const updateFailure = createAction(
  '[Housekeeper Scenarios] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Housekeeper Scenarios] Delete Request',
  props<{ scenarioId: number }>(),
);
export const deleteSuccess = createAction(
  '[Housekeeper Scenarios] Delete Success',
  props<{ scenarioId: number }>(),
);
export const deleteFailure = createAction(
  '[Housekeeper Scenarios] Delete Failure',
  props<{ error: any }>(),
);

export const exportLogsRequest = createAction(
  '[Housekeeper Scenarios] Export Logs Request',
  props<{ request: ExportScenariosLogsRequest }>(),
);
export const exportLogsSuccess = createAction(
  '[Housekeeper Scenarios] Export Logs Success',
);
export const exportLogsFailure = createAction(
  '[Housekeeper Scenarios] Export Logs Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Housekeeper Scenarios] Reset State');
